
/**
 * Overwriting console.log function to add the switch to disable the logging. By
 * default it is disabled. And developers have to call
 * window.consoleLogger.enable() to enable the colsole.log.
 */
window.consoleLogger = function() {
    var oldConsoleLogFn = null;
    return {
        enable : function enableLogger() {
            if (oldConsoleLogFn == null) {
                return;
            }
            window['console']['log'] = oldConsoleLogFn;
        },
        disable : function disableLogger() {
            oldConsoleLogFn = window['console']['log'];
            window['console']['log'] = function() {
            };
        }
    };
}();
//enable this for production
//window.consoleLogger.disable();

/*Generic method to trigger click when enter is pressed. This behavior is required for accessibility.*/
window.triggerClickOnEnter = function(selector) {
  if (selector) {
    $(selector).on("keypress", function(e) {
      if (e.which == 13) {
        $(this).trigger("click");
      }
    });
  }
};

/*Generic method to focus a specific element if that is visible. This behavior is required for accessibility.*/
window.focusElementIfVisible = function(selector, duration) {
  duration = duration || 500;
  setTimeout(function() {
    $(selector).each(function() {
      if ($(this).is(":visible")) {
        $(this).focus();
      }
    })
  }, duration);
};

var accessibilityEventBinder = window.accessibilityEventBinder || {};

accessibilityEventBinder.util = (function(){
  return {
    // Methods for Modal Accessibility
    modal : {
      'showHandler' : function(obj) { 
        var element = obj;
        $(element + " .modal-content h3").attr("tabindex","1").focus();
        $(element).attr({'aria-hidden': false});
        $(".modal-dialog").append('<a href="javascript:void(0);" class="accessibility-anchor"></a>');
      },
      'closeHandler' :function(obj) {
        var element = obj;
        $(element).parents(".modal").attr({'aria-hidden': true});
        $(document).find('.accessibility-anchor').remove();
      },
      'focusHandler' : function() {
        $(document).on("focus",".accessibility-anchor",function(){
          $(this).siblings(".modal-content").find("h3:visible").focus();
        });    
      }
    }
  }
}());

window.scrollTopWhenError = function(selector){
  selector = selector || ".form-statusbar";
  if( $(window).scrollTop() > $(selector).offset().top ){
    $("body").animate({
      scrollTop: $(selector).offset().top
    }, 1000);
  }
};

//this function will update the meta tag for viewport to disable zooming when user touches the form elements. And reset back when user comes out of it. 
window.disableFormAutoZoomForIOS = function(){
  var selector = 'select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"]';
  $(selector).on({
      'touchstart': function() {
          zoomDisable();
      }
  });
  $(selector).on({
      'touchend': function() {
          setTimeout(zoomEnable, 500);
      }
  });
  function zoomDisable() {
      try {
          $('head meta[data-rac-id=customVP]').remove();
          $('head').prepend('<meta name="viewport" data-rac-id="customVP" content="width=device-width, initial-scale=1.0, user-scalable=0" />');
      } catch (e) {
          alert("error");
      }
  }

  function zoomEnable() {
      try {
          $('head meta[data-rac-id=customVP]').remove();
          $('head').prepend('<meta name="viewport" data-rac-id="customVP" content="width=device-width, initial-scale=1.0, user-scalable=1" />');
      } catch (e) {
          alert("error");
      }
  }
};